<template>
  <div class="animated fadeIn col-sm-10 offset-sm-1">
    <b-card header-tag="header">
      <div slot="header">
        <i aria-hidden="false" class="icon-list"></i> Server picker
        <div class="card-header-actions"></div>
      </div>
      <b-card-body>
        <h3 class="text-center smalltitle">
          Hello, {{ $store.state.discord_user.username }}! Please select a server to get started
        </h3>
        <hr/>
        <b-row align-h="center">
          <div v-if="loading && sorted_guilds.length == 0">
            <div class="sk-circle-fade float-left">
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
            </div>
            <h2 class="float-left ml-4">Loading servers...</h2>
          </div>
          <div v-for="g in sorted_guilds" v-else :key="g.id" class="row">
            <b-col class="m-2 center">
              <span @click="handle_link(g)">
                 <VueImage
                     :src="g.icon"
                     :styles="get_style(g)"
                     :title="g.link ? 'Not yet invited, click to invite' : ''"
                     height="128"
                     width="128"
                 ></VueImage>
                 </span>
              <p class="text-center">{{ g.name }}</p>
            </b-col>


          </div>
        </b-row>
        <div v-if="loading && sorted_guilds.length !== 0">
          <div class="sk-circle-fade">
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import {inviteURIState} from "@/shared/constants";
import VueImage from "@/components/VueImage";

export default {
  name: "ServerPicker",
  components: {
     VueImage
   },
  data: function () {
    return {
      servers: [],
      query: "",
      loading: true,
    };
  },
  methods: {
    login(link) {
      window.location.href = link;
    },
    redirect_from_popup(e) {
      window.removeEventListener("message", this.redirect_from_popup);
      if (e.data.guild_id) {
        this.$router.push("/dashboard/" + e.data.guild_id);
      }
    },
    handle_link(g) {
      if (g.link) {
        return this.login(g.link);
      }
      return this.$router.push("/dashboard/" + g.id + "/botsettings");
    },
    get_style(g) {
      const styles = "cursor: pointer; border-radius: 50%; ";
       if (g.link === undefined && g.vip) {
         return styles + "border: 3px solid #ff675a";
      }
      return g.link === undefined
           ? styles + "border: 3px solid #6AFFAA;"
           : styles + "border: 3px solid #6AFFAA; -webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -ms-filter: grayscale(100%); -o-filter: grayscale(100%); filter: grayscale(100%);";
    },
  },

  computed: {
    sorted_guilds() {
      return this.$store.state.discord_guilds.sort(
         (a, b) =>
               (a.link || "").length - (b.link || "").length || a.name > b.name
      );
    },
  },
  created: function () {
    this.axios.get("/api/v1/users/@me/guilds").then((r) => {
      this.$store.commit("login", r.data.user);
      this.$store.commit("sync_guilds", r.data.guilds);
      this.loading = false;
      //this.servers = r.data.guilds
    });
  },
};
</script>
<style src="spinkit/spinkit.min.css"></style>
